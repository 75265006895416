import React, { MouseEvent } from "react";
import {
  Button,
  Pane,
  Paragraph,
  majorScale,
  Heading,
  Card,
} from "evergreen-ui";
import Collapsible from "react-collapsible";
import { listenLinks } from "../link-data";
import { trackLinkClick } from "../track";

function LandingPage() {
  const presaveUrl =
    "https://songsave.xyz/api/presave/5008d287-89cd-468e-ac20-61a87bacf130";

  return (
    <div>
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginTop={8}
        marginBottom={8}
      >
        <Heading size={900}>for tuesday</Heading>
      </Pane>

      {/* <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginLeft={0}
        marginRight={0}
        marginTop={16}
      >
        <Card padding={16} elevation={1}>
          <Pane display="flex" justifyContent="center" alignItems="center">
            <img
              style={{ width: "100%", maxWidth: "32rem" }}
              src="./album-art/z-album.jpg"
            ></img>
          </Pane>

          <Heading size={700} textAlign={"center"} marginTop={8}>
            a work in progress
          </Heading>
          <Paragraph
            fontSize={"8pt"}
            lineHeight={"normal"}
            textAlign={"center"}
            marginTop={4}
            marginBottom={8}
            style={{ maxWidth: "32rem" }}
          >
            i'm releasing this album 1 song at a time. <br />
            pre-saving means you will get each new song <br />
            from the album as they are released.
          </Paragraph>
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginX={majorScale(4)}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={presaveUrl}
              onClick={(event) => trackLinkClick(`presave`, presaveUrl, event)}
            >
              <Button
                size={"large"}
                appearance={"primary"}
                intent={"success"}
                marginTop={4}
                width={"100%"}
              >
                PRE-SAVE THE ALBUM
              </Button>
            </a>
          </Pane>
        </Card>
      </Pane> */}

      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginLeft={0}
        marginRight={0}
      >
        <Card padding={16} elevation={1}>
        <Pane display="flex" justifyContent="center" alignItems="center">
            <img
              style={{ width: "100%", maxWidth: "32rem" }}
              src="./album-art/zalbum-alternate7.jpg"
            ></img>
          </Pane>
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginLeft={0}
            marginRight={0}
          >
            <Paragraph style={{marginTop: '.5rem'}} textAlign={"center"} marginTop={0}>
              listen now via the links below &#11015;&#65039;
            </Paragraph>
          </Pane>

          <Pane marginTop={8}>
            {listenLinks.map((l, i) => (
              <Pane
                key={i}
                display="flex"
                justifyContent="center"
                marginBottom={8}
              >
                {i === 0 ? (
                  <Button
                    appearance="default"
                    width="100%"
                    style={{
                      minWidth: "18rem",
                      maxWidth: "41rem",
                      backgroundColor: "green",
                      opacity: "70%",
                    }}
                    onClick={(event: React.MouseEvent) =>
                      trackLinkClick(`${l.text}-links`, l.url, event)
                    }
                  >
                    <a
                      href={l.url}
                      style={{
                        display: "block",
                        marginBottom: ".3rem",
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                    </a>
                  </Button>
                ) : (
                  <Button
                    appearance="default"
                    width="100%"
                    style={{ minWidth: "18rem", maxWidth: "41rem" }}
                    onClick={(event: React.MouseEvent) =>
                      trackLinkClick(`${l.text}-links`, l.url, event)
                    }
                  >
                    <a
                      href={l.url}
                      style={{
                        display: "block",
                        marginBottom: ".3rem",
                        textDecoration: "none",
                        color: "DimGray",
                      }}
                    >
                      <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                    </a>
                  </Button>
                )}
              </Pane>
            ))}
          </Pane>
        </Card>
      </Pane>

      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginLeft={0}
        marginRight={0}
        marginTop={16}
      >
        <Card padding={16} elevation={1}>
          <Pane display="flex" justifyContent="center" alignItems="center">
            <img
              style={{ width: "100%", maxWidth: "32rem" }}
              src="./tote-bag.webp"
            ></img>
          </Pane>

          <Heading size={700} textAlign={"center"} marginTop={8}>
            for tuesday merch table
          </Heading>
          <Paragraph
            fontSize={"8pt"}
            lineHeight={"normal"}
            textAlign={"center"}
            marginTop={4}
            marginBottom={8}
            style={{ maxWidth: "32rem" }}
          >
            available now: a Red Horse-themed tote bag for your next trip to the farmer's market
          </Paragraph>

          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginX={majorScale(4)}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={"https://7076fb.myshopify.com/"}
              onClick={(event) =>
                trackLinkClick(
                  `shop`,
                  "https://7076fb.myshopify.com/",
                  event
                )
              }
            >
              <Button
                size={"large"}
                appearance={"primary"}
                intent={"success"}
                marginTop={4}
                width={"100%"}
              >
                MERCH TABLE
              </Button>
            </a>
          </Pane>
        </Card>
      </Pane>

      {/* <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginLeft={0}
        marginRight={0}
        marginTop={16}
      >
        <Card padding={16} elevation={1}>
          <Pane display="flex" justifyContent="center" alignItems="center">
            <img
              style={{ width: "100%", maxWidth: "32rem" }}
              src="./spruce-beanie.webp"
            ></img>
          </Pane>

          <Heading size={700} textAlign={"center"} marginTop={8}>
            for tuesday beanie
          </Heading>
          <Paragraph
            fontSize={"8pt"}
            lineHeight={"normal"}
            textAlign={"center"}
            marginTop={4}
            marginBottom={8}
            style={{ maxWidth: "32rem" }}
          >
            available now in a variety of colors: a trusty tuesday-themed beanie
            to keep my listener's ears warm and cozy.
          </Paragraph>

          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginX={majorScale(4)}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={"https://7076fb.myshopify.com/products/for-tuesday-beanie"}
              onClick={(event) =>
                trackLinkClick(
                  `beanie`,
                  "https://7076fb.myshopify.com/products/for-tuesday-beanie",
                  event
                )
              }
            >
              <Button
                size={"large"}
                appearance={"primary"}
                intent={"success"}
                marginTop={4}
                width={"100%"}
              >
                GET A BEANIE
              </Button>
            </a>
          </Pane>
        </Card>
      </Pane> */}

      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginTop={24}
        marginBottom={48}
      >
        <Pane>
          <Collapsible
            transitionTime={200}
            trigger={
              <Heading
                size={400}
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                  color: "lightgray",
                  cursor: "pointer",
                }}
              >
                other links (tap to expand)
              </Heading>
            }
          >
            <Pane style={{ maxWidth: "32rem" }} marginTop={16}>
              {listenLinks.map((l, i) => (
                <Pane
                  key={i}
                  display="flex"
                  justifyContent="center"
                  marginBottom={8}
                >
                  <Button
                    appearance="default"
                    width="100%"
                    style={{ minWidth: "18rem" }}
                    onClick={(event: React.MouseEvent) =>
                      trackLinkClick(`${l.text}-links`, l.url, event)
                    }
                  >
                    <a
                      href={l.url}
                      style={{
                        display: "block",
                        marginBottom: ".3rem",
                        textDecoration: "none",
                        color: "DimGray",
                      }}
                    >
                      <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                    </a>
                  </Button>
                </Pane>
              ))}
              <a
                href="https://fortuesday.com"
                style={{ textDecoration: "none" }}
              >
                <Heading
                  size={400}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    color: "lightgray",
                    cursor: "pointer",
                    marginBottom: ".5rem",
                  }}
                >
                  take me to fortuesday.com
                </Heading>
              </a>
            </Pane>
          </Collapsible>
        </Pane>
      </Pane>
    </div>
  );
}

export default LandingPage;
