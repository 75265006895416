import { v4 } from "uuid";
export function trackLinkClick(
  destinationName: string,
  destinationUrl: string,
  event: React.MouseEvent
) {
  event.preventDefault(); // block native link click
  const eventId = v4();
  const name = destinationName.replace(" ", "-");
  try {
    (window as any).fbq(
      "track",
      "ViewContent",
      {
        content_type: "product",
        content_ids: [name],
      },
      { eventID: eventId }
    );
  } catch (err) {
    console.error(err);
  }

  try {
    (window as any).insights.track({
      id: "listen-link-clicked",
      parameters: {
        name: name,
        url: destinationUrl,
      },
    });
  } catch (err) {
    console.error(err);
  }

  setTimeout(() => (window.document.location = destinationUrl), 500);
}
