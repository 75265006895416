import React, { MouseEvent } from "react";
import {
  Button,
  Pane,
  Paragraph,
  majorScale,
  Heading,
} from "evergreen-ui";
import { listenLinks } from "../link-data";
import { trackLinkClick } from "../track";

function PresaveSuccessPage() {
  const presaveUrl =
    "https://songsave.xyz/api/presave/5008d287-89cd-468e-ac20-61a87bacf130";

  return (
    <div>
      <Pane display="flex" justifyContent="center" alignItems="center">
        <img
          style={{
            objectFit: "cover",
            width: "100%",
            height: "12rem",
            objectPosition: "left",
          }}
          src="./fortuesday-banner.png"
        ></img>
      </Pane>
      <div style={{padding: '8px'}}>
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginX={majorScale(4)}
        marginTop={16}
        marginBottom={8}
      >
        <Heading size={800}>presave successful &#9989;</Heading>
      </Pane>

      <Pane
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginLeft={0}
        marginRight={0}
      >
        <Paragraph textAlign={"center"} marginTop={0}>
          listen to For Tuesday via the links below &#11015;&#65039;
        </Paragraph>
      </Pane>

      <Pane marginTop={16}>
        {listenLinks.map((l, i) => (
          <Pane key={i} display="flex" justifyContent="center" marginBottom={8}>
            {i === 0 ? (
              <Button
                appearance="default"
                width="100%"
                style={{
                  minWidth: "18rem",
                  maxWidth: "41rem",
                  backgroundColor: "green",
                  opacity: "70%",
                }}
                onClick={(event: React.MouseEvent) =>
                  trackLinkClick(`${l.text}-links`, l.url, event)
                }
              >
                <a
                  href={l.url}
                  style={{
                    display: "block",
                    marginBottom: ".3rem",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                </a>
              </Button>
            ) : (
              <Button
                appearance="default"
                width="100%"
                style={{ minWidth: "18rem", maxWidth: "41rem" }}
                onClick={(event: React.MouseEvent) =>
                  trackLinkClick(`${l.text}-links`, l.url, event)
                }
              >
                <a
                  href={l.url}
                  style={{
                    display: "block",
                    marginBottom: ".3rem",
                    textDecoration: "none",
                    color: "DimGray",
                  }}
                >
                  <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                </a>
              </Button>
            )}
          </Pane>
        ))}
        <a href="https://fortuesday.com" style={{ textDecoration: "none" }}>
          <Heading
            size={400}
            style={{
              textAlign: "center",
              width: "100%",
              height: "100%",
              color: "lightgray",
              cursor: "pointer",
            }}
          >
            take me to fortuesday.com
          </Heading>
        </a>
      </Pane>
      </div>
    </div>
  );
}

export default PresaveSuccessPage;
