import React from "react";
import InteractivePrompt from "./interactive-prompt/InteractivePrompt";
import Marquee from "react-fast-marquee";
import { trackLinkClick } from "./track";
import { infoLinks, listenLinks, socialLinks } from "./link-data";
import { v4 } from "uuid";

function InfoAccordion({
  title,
  children,
}: {
  title: string;
  children?: JSX.Element | JSX.Element[];
}) {
  const accordionId = v4();
  return (
    <div className="columns">
      <div className="col-12">
        <div className="accordion">
          <div className="divider"></div>
          <input
            type="checkbox"
            id={accordionId}
            name="accordion-checkbox"
            hidden
          />
          <label
            className="accordion-header"
            htmlFor={accordionId}
            style={{ marginLeft: "1rem" }}
          >
            <i
              className="fa-solid fa-plus"
              style={{ marginRight: ".5rem" }}
            ></i>{" "}
            {title}
          </label>
          <div
            className="accordion-body"
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              marginBottom: "0rem",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function Links() {
  return (
    <div>
      <div className="divider"></div>
      <div className="columns">
        <div className="column col-md-6 col-sm-12">
          <p
            className="text-small"
            style={{ marginBottom: ".3rem", marginTop: ".5rem" }}
          >
            <strong>listen</strong>
          </p>
          <div className="columns">
            <div className="column col-6">
              {listenLinks
                .slice(0, Math.ceil(listenLinks.length / 2))
                .map((l) => (
                  <a
                    href={l.url}
                    className="text-tiny"
                    style={{ display: "block", marginBottom: ".3rem" }}
                  >
                    <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                  </a>
                ))}
            </div>
            <div className="column col-6">
              {listenLinks
                .slice(Math.ceil(listenLinks.length / 2), listenLinks.length)
                .map((l) => (
                  <a
                    href={l.url}
                    className="text-tiny"
                    style={{ display: "block", marginBottom: ".3rem" }}
                  >
                    <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                  </a>
                ))}
            </div>
          </div>
        </div>

        <div className="column col-md-6 col-sm-12">
          <p
            className="text-small"
            style={{ marginBottom: ".3rem", marginTop: ".5rem" }}
          >
            <strong>connect</strong>
          </p>
          <div className="columns">
            <div className="column col-6">
              {socialLinks.map((l) => (
                <a
                  href={l.url}
                  className="text-tiny"
                  style={{ display: "block", marginBottom: ".3rem" }}
                >
                  <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                </a>
              ))}
            </div>
            <div className="column col-6">
              {infoLinks.map((l) => (
                <a
                  href={l.url}
                  className="text-tiny"
                  style={{ display: "block", marginBottom: ".3rem" }}
                >
                  <i className={l.icon}></i>&nbsp;{l.text}&nbsp;
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  require("spectre.css/dist/spectre.min.css");

  const subheadings = [
    "music good enough for a tuesday",
    "music for the grocery store",
    "music for driving home at 6pm on a weekday",
    "music to steep your tea to",
    "music for between the stacks",
    "music that reminds you to call someone",
    "music for AM radio",
    "music to send to an old friend",
    "music for your myspace top 10",
    "music anthony fantano would give a light 6",
  ];

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <h1 className="text-center">for tuesday</h1>
        </div>
      </div>

      <div className="columns">
        <div className="column col-12 hide-sm">
          <img
            className="img-responsive img-fit-contain"
            src="/fortuesday-banner.png"
            style={{ width: "100%" }}
          ></img>
          <Marquee>
            <h2
              className="text-italic"
              style={{ fontSize: ".8rem", margin: ".5rem" }}
            >
              {subheadings.map((s) => ` ${s}... `)}
            </h2>
          </Marquee>

          <div className="columns">
            <div className="col-mx-auto">
              <div style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
                {listenLinks
                  .filter((l) =>
                    ["spotify", "apple music", "bandcamp", "tidal"].includes(
                      l.text
                    )
                  )
                  .map((l) => (
                    <a
                      href={l.url}
                      onClick={(event) =>
                        trackLinkClick(`${l.text}-desktop`, l.url, event)
                      }
                      style={{ margin: ".5rem" }}
                    >
                      <button className="btn">listen on {l.text}</button>
                    </a>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="column col-6 col-ml-auto show-sm">
          <img
            className="img-responsive"
            src="/me.png"
            style={{ maxWidth: "8rem" }}
          ></img>
        </div>
        <div className="column col-6 show-sm">
          <InteractivePrompt></InteractivePrompt>
        </div>
      </div>

      <InfoAccordion title="about / bio">
        <p
          className="text-small"
          style={{ marginTop: ".5rem", marginBottom: "0rem" }}
        >
          <strong>For Tuesday</strong> is an eclectic indie music project from
          Mark Ferris. Dense, hazy, and cassette-processed, Mark draws influence
          from emo, shoegaze, new wave and folktronica while introducing his own
          direct, observational lyrical style. His recent EP "closer than a
          brother" was largely recorded on a solo trip to Appalachia and focuses
          on themes of Christian spirituality.
        </p>
      </InfoAccordion>

      <InfoAccordion title="discography">
        <div className="columns">
          <div className="column col-auto">
            <div style={{ marginTop: ".5rem" }}>
              <iframe
                style={{ border: 0, height: "21rem" }}
                src="https://bandcamp.com/EmbeddedPlayer/album=650975648/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
                seamless
              >
                <a href="https://fortuesday.bandcamp.com/album/count-me-in">
                  count me in by For Tuesday
                </a>
              </iframe>
            </div>
          </div>
          <div className="column col-auto">
            <div style={{ marginTop: ".5rem" }}>
              <iframe
                style={{ border: 0, height: "21rem" }}
                src="https://bandcamp.com/EmbeddedPlayer/album=1252899052/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
                seamless
              >
                <a href="https://fortuesday.bandcamp.com/album/closer-than-a-brother-2">
                  closer than a brother by For Tuesday
                </a>
              </iframe>
            </div>
          </div>
        </div>
      </InfoAccordion>

      <Links></Links>
    </div>
  );
}

export default App;
