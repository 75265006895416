import { useEffect, useRef } from "react"

function useTimeout(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)
  
    useEffect(() => {
  
      if (!delay && delay !== 0) {
        return
      }
      const id = setTimeout(() => savedCallback.current(), delay)
      return () => clearTimeout(id)
    }, [delay])
  }

  export default useTimeout;