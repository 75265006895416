import React, { useState } from "react";
import { Link, listenLinks, PrettyLink } from "../link-data";
import useTimeout from "./useTimeout";
import { search, fuzzy } from "fast-fuzzy";
import { trackLinkClick } from "../track";

function MusicLinkMessage({ selection }: { selection: string }) {
  const [displayMessage, setDisplayMessage] = useState(false);
  useTimeout(() => setDisplayMessage(true), 800);

  let guesses = search(selection, listenLinks, {
    keySelector: (obj: Link) => obj.text,
  });
  let bestGuess = guesses[0];
  if (bestGuess == null) {
    const deeperGuesses = listenLinks
      .map((l) => {
        const score = fuzzy(l.text, selection);
        if (score > 0.5) {
          return { link: l, score: score || 0 };
        }
      })
      .sort((a, b) => {
        return (b as { score: number }).score - (a as { score: number }).score;
      }) as { link: PrettyLink; score: number }[];
    bestGuess = deeperGuesses[0]?.link;
  }

  if (bestGuess == null) {
    // STILL not sure
    bestGuess = listenLinks.find((l) =>
      l.url.includes("spotify.com")
    ) as PrettyLink;
  }

  return (
    <div>
      {displayMessage && bestGuess && (
        <div className="yours messages">
          <div className="message last">
            <a
              onClick={(event) => trackLinkClick(`${bestGuess.text}-mobile`, bestGuess.url, event)}
              href={bestGuess.url}
            >
              {bestGuess.url}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default MusicLinkMessage;
