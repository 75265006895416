export interface Link {
  text: string;
  url: string;
}

export interface PrettyLink extends Link {
  icon: string;
}

export const listenLinks: PrettyLink[] = [
  {
    text: "spotify",
    icon: "fa-brands fa-spotify",
    url: "https://open.spotify.com/artist/3gD5zen7An6834iN8hS22F?si=SCDK6nw7Qzycra8Cqkaxww",
  },
  {
    text: "apple music",
    icon: "fa-solid fa-music",
    url: "https://music.apple.com/us/artist/for-tuesday/1524302482",
  },
  {
    text: "bandcamp",
    icon: "fa-brands fa-bandcamp",
    url: "https://fortuesday.bandcamp.com/",
  },
  {
    text: "tidal",
    icon: "fa-solid fa-grip",
    url: "https://store.tidal.com/us/artist/20598332",
  },
  {
    text: "amazon music",
    icon: "fa-brands fa-amazon",
    url: "https://music.amazon.com/artists/B08DLPSZPC/for-tuesday",
  },
  {
    text: "soundcloud",
    icon: "fa-brands fa-soundcloud",
    url: "https://soundcloud.com/fortuesday",
  },
  {
    text: "youtube music",
    icon: "fa-brands fa-youtube",
    url: "https://music.youtube.com/channel/UCNW1jO4X1Dkay1Fly2ZNIEQ",
  },
  {
    text: "youtube",
    icon: "fa-brands fa-youtube",
    url: "https://www.youtube.com/channel/UC80Diql-lZi_AqhOQWO8mig",
  },
  {
    text: "deezer",
    icon: "fa-brands fa-deezer",
    url: "https://www.deezer.com/us/artist/102017542"
  }
];

export const socialLinks: PrettyLink[] = [
  {
    text: "instagram",
    icon: "fa-brands fa-instagram",
    url: "https://www.instagram.com/for.tuesday",
  },
  {
    text: "tiktok",
    icon: "fa-brands fa-tiktok",
    url: "https://tiktok.com/@for.tuesday",
  },
  {
    text: "twitter",
    icon: "fa-brands fa-twitter",
    url: "https://twitter.com/fortuesdaymusic",
  },
  {
    text: "facebook",
    icon: "fa-brands fa-facebook",
    url: "https://www.facebook.com/fortuesday",
  },
];

export const infoLinks: PrettyLink[] = [
  {
    text: "genius",
    icon: "fa-solid fa-pen",
    url: "https://genius.com/artists/For-tuesday",
  },
  {
    text: "musixmatch",
    icon: "fa-regular fa-m",
    url: "https://www.musixmatch.com/artist/For-Tuesday",
  },
  {
    text: "musicbrainz",
    icon: "fa-solid fa-brain",
    url: "https://musicbrainz.org/artist/c023859d-1f65-4285-afff-ceb1583c876c",
  },
  {
    text: "wikidata",
    icon: "fa-solid fa-database",
    url: "https://www.wikidata.org/wiki/Q103243237",
  },
];
