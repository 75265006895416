import React, { useEffect, useState } from "react";
import "./listen.css";
import useTimeout from "./useTimeout";
import InlineAutocomplete from "react-inline-autocomplete";
import { DataSourceItem } from "react-inline-autocomplete/dist";
import { listenLinks } from "../link-data";
import MusicLinkMessage from "./MusicLinkMessage";

function MessageGroup({ messages }: { messages: string[] }) {
  return (
    <div>
      {messages.map((m, i, arr) => {
        let classNames = "message";
        if (arr[i + 1] == null) {
          classNames += " last";
        }
        return <div className={classNames}>{m}</div>;
      })}
    </div>
  );
}

function SentMessage({ messages }: { messages: string[] }) {
  return (
    <div className="mine messages">
      <MessageGroup messages={messages}></MessageGroup>
    </div>
  );
}

function RecievedMessage({ messages }: { messages: string[] }) {
  return (
    <div className="yours messages">
      <MessageGroup messages={messages}></MessageGroup>
    </div>
  );
}

type MessageThread = {
  type: "sent" | "recieved";
  text: string;
}[];

const welcomeThread: MessageThread = [
  {
    type: "recieved",
    text: "yoooo",
  },
  {
    type: "recieved",
    text: "listen to my new tuness",
  },
  {
    type: "sent",
    text: "ok fine where at",
  },
  {
    type: "recieved",
    text: "uhh what app do you use for music?",
  },
];

const dataSource: DataSourceItem[] = listenLinks.map((l) => ({
  text: l.text,
  value: l.text,
}));

function InteractivePrompt() {
  require('react-inline-autocomplete/dist/index.css');
  // chat messages
  const [isTyping, setIsTyping] = useState(false);
  const [displayAck, setDisplayAck] = useState(false);
  const [displayReply, setDisplayReply] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [selection, setSelection] = useState<null | string>(null);
  useTimeout(() => setDisplayAck(true), 1200);
  useTimeout(() => setIsTyping(true), 1800);
  useTimeout(() => setIsTyping(false), 2800);
  useTimeout(() => {
    setDisplayReply(true);
    setDisplayForm(true);
  }, 2800);

  // form
  const ref = React.createRef<HTMLInputElement>();
  const focus = () => {
    ref.current!.focus();
  };
  const onChange = (value: string) => {};
  const onPressEnter = (value: string) => {};
  const onSelect = (item: DataSourceItem) => {};

  useEffect(() => {
    if (displayForm && ref.current) {
      ref.current.focus();
    }
  }, [displayForm, ref]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setSelection(ref.current?.value as string);
    event.preventDefault();
  }

  return (
    <div>
      <div className="chat">
        <RecievedMessage
          messages={["yoooo", "listen to my new tuness"]}
        ></RecievedMessage>
        {displayAck && (
          <SentMessage messages={["ok fine where at?"]}></SentMessage>
        )}
        {isTyping && (
          <img src="/typing.gif" style={{ maxWidth: "3.5rem" }}></img>
        )}
        {displayReply && (
          <RecievedMessage
            messages={["uhh what app do you use for music"]}
          ></RecievedMessage>
        )}

        {selection && (
          <div>
            <div style={{marginRight: '1rem'}}><SentMessage messages={[selection]}></SentMessage></div>
            <div style={{wordBreak: 'break-all'}}>
            <MusicLinkMessage selection={selection}></MusicLinkMessage>
            </div>
            </div>
        )}
      </div>
      {(displayForm && !selection) && (
        <div className="form-group" style={{ marginTop: ".5rem" }}>
          <form onSubmit={handleSubmit}>
            <div className="has-icon-right">
              <InlineAutocomplete
                ref={ref}
                dataSource={dataSource}
                caseSensitive={false}
                onChange={onChange}
                onPressEnter={onPressEnter}
                onSelect={onSelect}
                placeholder="Type a response..."
              ></InlineAutocomplete>

              <button
                type="submit"
                className="form-icon icon"
                style={{
                  background: "none",
                  color: "inherit",
                  border: "none",
                  padding: "0",
                  font: "inherit",
                  outline: "inherit",
                  position: "absolute",
                  width: "2rem",
                }}
              >
                <i
                  className="fa-solid fa-chevron-right"
                  style={{
                    marginBottom: "1rem",
                    marginRight: ".5rem",
                    float: "right",
                  }}
                ></i>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default InteractivePrompt;
